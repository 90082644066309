import React from 'react'
import style from './InnerHeadBar.module.css'
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";
import NotificationICon from "../../assests/26. Notification.png"
import MessageICon from "../../assests/Inbox.png";
import { useNavigate } from 'react-router-dom';


const InnerHeadBar = () => {
  const navigate=useNavigate();
  const UserName = localStorage.getItem("username");

  const getInitials = (name) => {
    if (!name) return '';

    const nameParts = name.trim().split(' ');
    
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      // If two or more names, return the first letter of the first two names
      return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
    }
  };

  const userInitials = getInitials(UserName);

  return (
    <div className={style.Container}>
    <div className={style.topBar}>
      <div className={style.topImg}>
        <img src={EnglishWagonLogo} alt="English Wagon Logo" />
      </div>

      <div className={style.topBarMenu}>
      <div className={style.notificationIconImage}>
          <img src={MessageICon} alt='Inbox'/>
        </div>
        <div className={style.iconImage}>
          <img src={NotificationICon} alt='notification'/>
        </div>
        <div className={style.userIcon}>
            <span onClick={()=>{
              navigate("/profile")
            }}>{userInitials}</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default InnerHeadBar