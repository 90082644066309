import React from 'react'
import { Outlet } from 'react-router-dom';
import Cookie from './Cookie/Cookie';
import InnerHeadBar from './InnerHeadBar/InnerHeadBar'
import Sidebar from './NewSidebar/Sidebar'
const Layout = () => {
  const layoutContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  const mainContentStyle = {
    display: 'flex',
    flex: 1,
  };

  const contentStyle = {
    flex: 1,
  };



  return (
    <div style={layoutContainerStyle}>
        <InnerHeadBar />
      <div style={mainContentStyle}>
          <Sidebar />
        <div style={contentStyle}>
          <Outlet />
        </div>
      </div>
        <Cookie />
    </div>
  )
}

export default Layout